.agreementsContainer {
  max-width: 136.5rem;
  margin: 8.4rem auto 14.4rem;
  padding: 0px 16px;

.agreementsContainer_tabs{
  margin-bottom: 32px;
}
  .CountDiv {
    position: absolute;
    right: 100px;
  }

  h2 {
    text-transform: capitalize;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 2.5rem;
    line-height: 2.93rem;

    text-align: left;

    color: #332486;
    margin-bottom: 5rem;
    width: max-content;

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: .2rem;
      background-color: #8FC54D;
      margin-top: 2.5rem;
    }
  }

  .seeMore {
    width: 100%;

    font-style: normal;
    font-weight: 600;
    font-size: 2.0rem;
    line-height: 2.3rem;
    text-align: center;
    color: #8FC54D;

    display: flex;
    margin: 8.8rem 0 0 0;
    justify-content: flex-end;
  }
  .seeMore:hover{
    transform: scale(1) !important;
    font-weight: 700;
  }

  .banner {
    //display: flex;
    //align-items: center;
    //justify-content: space-between;
    //flex-wrap: wrap;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 24px;
    margin-bottom: 6rem;


    > div {
      width: 100%;

    }
  }

  .certeficateBtn_block {
    display: flex;
    flex-direction: column;
    justify-content: start;
    position: relative;

    p {
      margin-left: 10px;
      margin-top: 10px;
    }

    .view_btn {
      display: none;
      width: 126px;
      padding: 12px 32px;
      border-radius: 142px;
      background: #332486;
      color: white;
      font-weight: 600;
      position: absolute;
      bottom: 0%;
      left: 70%;
      transform: translate(0%, 0%);
    }
  }

  .agreementBtn {
    /*  margin-bottom: 30px;*/
    //margin-left: 20px;
    margin: auto;
    width: 185px;
    height: 181px;
    background: #FFFFFF;
    border: 1px solid rgba(51, 36, 134, 0.3);
    box-shadow: 0 4px 12px rgba(51, 36, 134, 0.3);
    border-radius: 4px;
    overflow: hidden;
  }

  .certeficateBtn_block:hover .view_btn {
    display: block !important;
    padding: 6px 16px;
    position: absolute;
    bottom: 20%;
    left: 18%;
  }

}

@media (max-width: 1350px) {
  .agreementsContainer .banner {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    justify-content: center;
  }
}

@media (max-width: 1050px) {
  .agreementsContainer .banner {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: center;
  }
}

@media (max-width: 800px) {
  .agreement_proposal_cont {
    margin: 45px auto 0;
  }
  .agreement_proposal_item_b1 span {
    font-size: 2rem;
  }
  .agreement_proposal_item {
    margin-bottom: 24px;
  }
  .agreement_proposal_btns_b button {
    padding: 5px 14px;
    font-size: 2rem;
    line-height: 28px;
  }
  .agreementsContainer .banner {
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
  }
  .agreement_proposal_cont {
    margin: 24px auto 0;
  }
}

@media(max-width: 650px) {
  .agreement_proposal_item {
    flex-direction: column;
    padding: 16px;
  }
  .agreement_proposal_item_b1 {
    flex-direction: column;
  }
  .agreement_photo_b {
    margin-right: 0px;
    margin-bottom: 16px;
  }
  .agreement_proposal_btns_b {
    display: flex;
    justify-content: center;
    margin-left: 0px;
    margin-top: 16px;
  }
  .agreement_proposal_btns_b button:last-child {
    margin-left: 0px;
  }
  .agreement_proposal_btns_b button:first-child {
    margin-right: 16px;
  }
  .agreementsContainer .banner {
    grid-template-columns: 1fr 1fr;
    justify-content: center;
  }
  .agreementsContainer .agreementBtn {
    max-width: 185px;
    width: 100%;
  }
}

@media (max-width: 390px) {
  .agreementsContainer .banner {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
}
