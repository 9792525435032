.certeficate {
  width: 100%;
  max-width: 121.2rem;
  padding: 10.7rem 3rem 1.8rem;
  margin: auto;
  position: relative;

  .body {
    display: flex;
    width: 100%;
    max-width: 82.1rem;
    justify-content: space-between;
    margin-bottom: 34.2rem;

    > div {
      display: flex;
      //justify-content: space-between;
      flex-direction: column;
    }
    .accepted_icon{
      position: absolute;
      top: 1%;
      right: 5%;
    }

  }


  .photoContent {
    width: 308px;
    height: 272px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 1px 11px rgba(51, 36, 134, 0.2);


    span {
      height: max-content;
      max-width: 30%;
      text-align: center;
    }
  }

  .apply_btn {
    position: absolute;
    right: 0;
    width: max-content;
    background: #332486;
    border-radius: 142px;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.9rem;
    padding: 0.85rem 4.05rem;
    color: #FFFFFF;

  }

  .settings {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: start;


    label {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 23px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #565656;

      &:first-child {
        margin-right: 3.2rem;
      }
    ;
    }

    input {
      margin-right: .6rem;
    }
  }

  p {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 2.0rem;
    line-height: 2.3rem;
    margin-bottom: 2rem;
    color: #565656;

  }

  h5 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;

    color: #565656;
  }

  .qrContent {
    max-width: 36.2rem;
    margin: auto;

    p {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 2.0rem;
      line-height: 2.3rem;
      margin-top: 1.4rem;
      color: #565656;
    }
  }
}

.qr_content{
  margin-top: 160px !important;
  max-width: 362px !important;
  width: 100%;

}
.QR_code_img{
  padding: 30px;
  width: 100% !important;
}

.certificate_item {
  margin-bottom: 24px !important;
  margin-top: 8px !important;
  display: flex !important;
  flex-direction: column !important;
  width: 100%;
}

.certificate_item textarea {
  margin-top: 2px !important;
  padding: 10px;
}
.certificate_item_desc{
  font-weight: 700 !important;
  margin-top: 96px !important;
}

.certificate_items_block {
  max-width: 395px;
  width: 100%;
  margin-left: 32px;
}
.certificate_items_block .title{
  font-weight: 700;
  margin-bottom: 0rem !important;
  margin-top: 1em !important;
}
.certificate_items_block .file_name{
 color: #332486;
}
.discard_save_b {
  margin-top: 10px;
  width: max-content;
  margin-left: 32px;
}

.discard_btn {
  width: 124px;
  height: 43px;
  border: 1px solid #332486;
  border-radius: 29px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #332486;
}

.save_btn {
  width: 172px;
  height: 43px;
  background: #332486;
  border-radius: 29px;
  color: white;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin-left: 24px;
}

.settings_item input {
  width: max-content !important;
}

.settings_item {
  justify-content: start !important;
  width: unset !important;
}
.send_to_select{
  margin-top: 32px;
}
.send_to_select button{
  border-radius: 29px;
  border: 1px solid #332486;
  display: flex;
  max-width: 306px;
  width: 100%;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  gap: 32px;
  margin-bottom: 16px;
  color: #332486;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

input[type='radio'] {
  accent-color: #756DA3;
  width: 20px !important;
  height: 20px !important;
}
.certificate_desc_settings{
  display: flow;
  justify-content: space-between;
}
.certificate_desc_settings label{
  margin-right: 32px !important;
}


.currency_value_block{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.currency_value_block .certificate_item:first-child{
 margin-right: 16px;
}
.certificate_items_block .certificate_item input, .certificate_item select, .certificate_item textarea {
  background: #E4E4E4;
}
@media (max-width: 650px) {
  .certeficate .body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .certificate_items_block {
    margin-top: 16px;
    margin-left: 0px;
  }
  .discard_save_b {
    margin-left: 0px;
  }
  .certeficate .settings {
    justify-content: center;
  }

}
.apply_b{
  margin-top: 32px !important;
}
.certificate_view_item_block{
  max-width: 456px !important;
  width: 100% !important;
}
@media (max-width: 414px) {
  .certeficate {
    width: 100%;
    max-width: 141.2rem;
    padding: 10.7rem 3rem 17.8rem;
    margin: auto;
    position: relative;
  }
}

@media (max-width: 360px) {
  .discard_save_b {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 250px;
  }
  .save_btn {
    margin-left: 0px;
    margin-top: 16px;
  }
  .certificate_items_block {
    width: 250px;
  }
  .certificate_item {
    margin-top: 8px !important;
  }
}
