.messages_container {
    max-width: 1440px;
    width: 100%;
    height: 100%;
    background: white;
    margin: 38px auto 100px;
    border: 1px solid #D0C6E6;
    border-radius: 12px;
    display: flex;
}

.messages_cont1 {
    max-width: 398px;
    width: 100%;
    border-right: 1px solid #D0C6E6;
    height: 100vh;
}

.search_block {
    padding: 34px 12px 9px;
    position: relative;
}

.search_block input {
    padding-left: 47px !important;
}

.search_block img {
    position: absolute;
    top: 50%;
    left: 18px;
}

.messages_cont1_b2_item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    padding: 16px 11px;
    border-bottom: 1px solid #D0C6E6;
    cursor: pointer;
}

.messages_cont1_b2_item_active {
    background: #ECE8F5;
    border-left: 2px solid #332486;
}

.messages_cont1_b2_item:last-child {
    border-bottom: none;
}

.messages_cont1_b2_item .author_pic {
    width: 48px;
    height: 48px;
    margin-right: 16px;
}

.name_b label {
    font-family: 'Roboto' !important;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    /*line-height: 24px;*/
    color: #000000;
    margin-bottom: 4px;
}

.name_b div {
    font-family: 'Roboto' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
}

.day_time_b {
    font-family: 'Roboto' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: #949494;
}

.messages_cont2 {
    max-height: 1044px;
    width: 100%;
    height: 100%;
}

.start_t {
    text-align: center;
    margin: 25% auto;
}

.messages_cont2_inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px;
    height: 100vh;
    overflow-y: auto;
}

.day_b {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.day_b .line {
    width: 100%;
    height: 1px;
    background: #949494;
}

.day_b p {
    padding: 11px;
}

.messages_sent_block {
    position: relative;
}

.send_message_btn {
    position: absolute;
    top: 3%;
    right: 0.2%;
    max-width: 35px;
    width: 100%;
}

.send_message_btn img {
    max-width: 35px;
    width: 100%;
}

.send_message_block {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 40px;
}

.send_message_block_2_b1 {
    display: flex;
    flex-direction: row;
    justify-content: end;
}

.send_message_block_2_b1 div {
    display: flex;
    flex-direction: column;
    align-items: end;
    margin-right: 12px;
}

.messages_block {
    max-width: 300px;
    width: max-content;
    height: max-content;
    padding: 8px;
    background: #ECE8F5;
    border-radius: 0px 8px 8px 8px;
    margin-top: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
}

.send_message_block_2_b1 div label, .receive_message_block_1_b1 div label {
    font-family: 'Roboto' !important;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: black;
}

.send_message_block_2_b1 div span, .receive_message_block_1_b1 div span {
    font-family: 'Roboto' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #949494;
}
.receive_message_block{
    margin-bottom: 40px;
}
.receive_message_block_1_b1 {
    display: flex;
}

.receive_message_block_1_b1 div {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
}
.send_receive_messages_blocks{
    height:100%;
    /* overflow-y: auto; */
    margin-top: 12px;
    margin-bottom: 24px;
}