* {
  margin: 0;
  padding: 0;
  outline: none;
  color: #101820;
  box-sizing: border-box;
  font-family: Roboto-Regular, sans-serif !important;
  //This defines what 1rem is
  font-size: 10px;
  line-height: 10px;

  //Delete this Style
  //box-shadow: 0 0 3px #666;
}

body * {
  font-size: 1.6rem;
  line-height: 3.2rem;
}

body {
  background-color: #F5F5F5;

}

section {
  background-color: #F5F5F5;

}

img {
  //height: auto;
  display: block;
  max-width: 100%;
  height: max-content;
  object-fit: contain;
}

.imgContent {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  //background: rgba(0,0,0,.5);
  justify-content: center;
}

.dot_red {
  left: -30px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
  position: absolute;
  top: -10px
}

*::-webkit-scrollbar {
  width: 5px;
  background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #d9d9d9 0%, rgba(227, 228, 229, 0) 100%);
  border-radius: 10px;
}

section {
  min-height: 300px;
}

h3, h3 > span {
  font-weight: 600;
  font-size: 4.8rem;
  line-height: 7.2rem;

  color: #332486;
  margin-bottom: 10rem;
  text-align: center;

}

h3:after {
  content: '';
  width: 100%;
  height: .3rem;
  background-color: #8FC54D;

  max-width: 12rem;
  display: block;
  margin: 4rem auto 0;
}

h3 > span {
  color: #8FC54D;
}

button {
  cursor: pointer;
}


label, select {
  width: 100%;
  max-width: 39.5rem;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: #565656;
  margin-bottom: .2rem;


}

select {
  padding: 1rem 1.5rem;
  border: 1px solid rgba(51, 36, 134, 0.6);
  border-radius: 4px;
  display: block;

}

input {
  width: 100%;
  padding: 1rem 1.5rem;
  border: 1px solid rgba(51, 36, 134, 0.6);
  border-radius: 4px;
  line-height: initial;

  &::placeholder {
    color: #A1A5B6;
    text-transform: capitalize;
  }
}

.showModal .modalBc {
  background-color: rgba(0, 0, 0, 0.5);
  //background-color: rgba(62, 67, 67, 0.1);
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

}

.showModal .modalBc {
  display: block;
}

.modal {


  .modalContent {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    max-height: 742px;
    max-width: 802px;
    background-color: #fff;
    border-radius: 4px;
    padding: 56px 113px 108px;

    .closeBtn {
      position: absolute;
      right: 0;
    }

    .modalBody {
      width: 100%;
      height: 100%;
      position: relative;
    }

  }
}


////////////////////////// Switch Button
.saveCard button {
  background-color: lightgray;
  border: 0;
  border-radius: 50px;
  cursor: pointer;
  height: 31px;
  position: relative;
  width: 55px;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin-right: 20px;
}

.saveCard button:hover {
  background-color: lightgray;
}

.saveCard button:focus,
.saveCard button:active {
  outline: none;
}

.saveCard button::-moz-focus-inner {
  border: 0;
}

.pin {
  background-color: white;
  border-radius: 98px;
  height: 31px;
  left: 0;
  position: absolute;
  top: 0;
  width: 31px;
  transition: left ease .5s;
}

.saveCard button.on {
  background-color: #332486;
}

.saveCard button.on .pin {
  left: 25px;
}

.text_center {
  text-align: center;
}

////////////////////////// Switch Button


@media screen and (max-width: 1200px) {
  html {
    font-size: 8px;
    line-height: 8px;
  }

}

@media screen and (max-width: 992px) {
  html {
    font-size: 7px;
    line-height: 7px;
  }
  .modal .modalContent {
    padding: 56px;
  }
}

@media screen and (max-width: 769px) {
  html {
    font-size: 6px;
    line-height: 6px;
  }
}

@media screen and (max-width: 480px) {
  html {
    font-size: 5px;
    line-height: 5px;
  }
  .modal .modalContent {
    padding: 24px;
  }
  .modal.addCardModal .btnsContent {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 350px) {
  html {
    font-size: 4px;
    line-height: 4px;
  }
}
