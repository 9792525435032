.addIpContent {
  max-width: 1391px;
  background-color: #fff;
  margin: 192px auto 303px;
  border: 1px solid #E8E7E7;
  padding: 146px 159px 69px 127px;

  .chooseFile {
    display: flex;
    align-items: center;
    width: 100%;
    //padding: 1rem 1.5rem;
    border: 1px solid rgba(51, 36, 134, 0.6);
    border-radius: 4px;
    line-height: initial;
    height: max-content;

    input {
      display: none;


    }

    span {
      background: #FFFFFF;
      border-right: 1px solid rgba(51, 36, 134, 0.7);
      border-radius: 4px;
      font-size: 12px;
      margin: 0 32px 0 0;
      line-height: 14px;
      /* identical to box height */

      padding: 12px 20px;
      color: #332486;

    }
  }

  .fileNameContent {
    //background-color: red;
  }

  .formGroup {
    margin-top: 54px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 54px;
    column-gap: 70px;
    justify-content: space-between;
    background-color: #fff;

    &:first-child {
      margin-bottom: 54px;
    }

    //input, label, select{
    //   background-color: #fff;
    // }


  }

  button {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    height: max-content;
    color: #FFFFFF;
    background: #332486;
    border-radius: 29px;
    padding: 10px 31px;
    align-self: flex-end;
    display: flex;
    margin: 109px auto 0;
  }
}

.ddIpContent_b1 {
  max-width: 200px !important;
  width: 100% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: -13px !important;
  display: flex !important;
  justify-content: center !important;
}

.ddIpContent_b1_item {
  display: flex !important;
  align-items: center !important;
}

.ddIpContent_b1_item input {
  margin-right: 5px !important;
}

.ddIpContent_b1_item:first-child {
  margin-right: 28px !important;
}

.desc_block {
  margin-top: 54px;
}

.country_issued_b {
  position: relative;
  width: 100%;
  max-width: 39.5rem;
  margin-top: -15px;
}

.country_issued_b input {
  margin-top: -15px !important;
}

.country_issued_b_dropdown {
  display: block;
  position: absolute;
  top: 88%;
  border-bottom: 1px solid rgba(51, 36, 134, 0.6);
  border-left: 1px solid rgba(51, 36, 134, 0.6);
  border-right: 1px solid rgba(51, 36, 134, 0.6);
  height: 150px;
  overflow-y: auto;
  width: 100%;
  background: white;
  padding: 5px 10px;
}

.country_issued_b_dropdown div {
  cursor: pointer;
}
.calendar_block{
  position: relative;
  display: flex;
  flex-direction: column;
}
.calendar_block .calendar_icon{
  position: absolute;
  top: 50%;
  right: 4%;
  z-index: 1;
  cursor: pointer;
}
.react-datepicker {
  width: 250px !important;
}
.react-datepicker__navigation--next, .react-datepicker__navigation--previous{
  background: transparent !important;
  color:#1B105A !important;
  padding: 0px !important;
}
.react-datepicker__month-container {
  float: contour !important;
  width: 250px !important;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  width: 2.6rem !important;
}
.react-datepicker__triangle{
  transform: translate(19px, 0px) !important;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #332486 !important;
  padding: 3px 5px !important;
  color: #fff;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #cecbcb !important;
  color: rgb(0, 0, 0);
  padding: 3px 5px !important;
}

@media(max-width: 910px) {
  .addIpContent{
 padding: 60px;
    margin: 60px auto;
  }
  .addIpContent .formGroup {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 24px;
    column-gap: 24px;
    justify-content: center;
    background-color: #fff;
    flex-direction: column;
    max-width: unset;
    width: 100%;
  }
  .addIpContent .formGroup label {
    max-width: unset;
    width: 100%;
  }
  .addIpContent .formGroup select {
    max-width: unset;
    width: 100%;
  }
  .country_issued_b {
    max-width: unset;
  }
  .addIpContent button {
    margin: 24px auto 0;
    font-size: 16px;
  }
}

@media (max-width: 414px) {
  .addIpContent {
    padding: 30px;
    margin: 40px auto;
  }
}