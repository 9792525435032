.getStartedBanner {
  .swiper-slide {
    background-color: rgba(0, 0, 0, .5);
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    > p {
      color: #fff;
      font-size: 4.8rem;
      position: relative;
      z-index: 1;
    }

    &:before {
      content: '';
      background: linear-gradient(90deg, rgba(7, 4, 19, 0.75) 0%, rgba(27, 16, 90, 0.75) 51.9%, rgba(148, 136, 213, 0.75) 102.2%);
      position: absolute;
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
    }

    .body {
      max-width: 103.7rem;
      height: max-content;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;

      h2 {
        font-weight: 600;
        font-size: 6.4rem;
        line-height: 9.6rem;
        color: #FFFFFF;
        margin-bottom: 7.7rem;
      }

      button {
        background: #8FC54D;
        border: 1px solid #8FC54D;
        border-radius: 22px;
        padding: .9rem 3.1rem;

        font-weight: 600;
        font-size: 1.6rem;
        line-height: 1.9rem;
        color: #FFFFFF;

      }
    }
  }

  .swiper-pagination {
    margin-bottom: 4.3rem;
  }

  .swiper-pagination-bullet {
    height: .3rem;
    width: 3rem;
    background-color: #8FC54D;
    margin: 0 2.4rem 0 0;
    border-radius: initial;

    &:last-child {
      margin: 0;
    }
  }
}
@media(max-width: 675px){
  .getStartedBanner .swiper-slide .body {
    left: 5%;
    bottom: 10%;
  }
  .getStartedBanner .swiper-slide .body h2 {
    font-size: 6rem;
    line-height: 7.6rem;
    margin-bottom: 2.7rem;
  }
}
@media(max-width: 460px){
  .getStartedBanner .swiper-slide .body {
    bottom: 15%;
  }
  .getStartedBanner .swiper-slide .body h2 {
    font-size: 4rem;
  }
  .getStartedBanner .swiper-pagination {
    margin-bottom: 1.3rem;
  }
}