

@media (max-width: 1800px) {
    .home .whatWeDo .content .aside {
        padding: 0 3rem;
    }
}

@media (max-width: 1600px) {
    .home .whatWeDo .content img {
        max-width: 50%;
    }

    .home .whatWeDo .content .aside {
        padding: 0 3rem;
    }

    .home .contactUs .content h3 {
        margin-bottom: 10rem;
    }
}

@media (max-width: 1400px) {
    .home .whatWeDo .content h4, .home .whatWeDo .content span {
        margin-bottom: 2rem;
    }
}


@media (max-width: 1200px) {

    .home .whatWeDo {
        margin-top: 5rem;
    }

    h3, h3 > span {
        line-height: 3rem;
        margin-bottom: 5rem;
    }

    .home .whyChooseIPD .content {
        margin: 5rem auto 0;
    }

    .home .whyChooseIPD h3 {
        margin-bottom: 5rem;
    }

    .home .howItWorks .item .body > div {
        padding: 1.5rem;
    }

    .home .advertiseYourConcept h2 {
        margin-bottom: 5rem;
    }

}


@media (max-width: 992px) {
    .home .whyChooseIPD .item {
        margin: 0;
    }

    .home .whyChooseIPD .content {
        column-gap: 5rem;
        padding: 0 3rem;
        width: 100%;
    }

    .home .howItWorks .item:after {
        width: 20rem;
    }

    .home .whatWeDo .content {
        flex-direction: column;
    }

    .home .whatWeDo .content img {
        max-width: 100%;

    }

    .home .whatWeDo .content .aside {
        padding: 5rem 3rem;
    }

    .home .whatWeDo .content .aside > div {
        max-width: 100%;
    }
}
@media(max-width: 885px){
    .home .whatWeDo {
        margin-top: 0rem;
    }
    .home .whatWeDo .content h4{
       text-align: center;
    }
}

@media (max-width: 769px) {
    .home .getStartedBanner .swiper-slide .body{
        padding-left: 3rem;

    }
    .home .getStartedBanner .swiper-slide .body h2 {
        font-size: 5rem;
        line-height: 8rem;
        margin-bottom: 4rem;
    }
    .home .howItWorks .item:after {
        width: 7rem;
    }

    .home .advertiseYourConcept {
        padding: 0 3rem;
    }

    .home .advertiseYourConcept h2 {
        font-size: 3.5rem;
    }

    h3, h3 > span {
        font-size: 3.8rem;
    }

    h3:after {
        margin: 3rem auto 0;
    }
    .home .howItWorks .item p {
        margin-top: 2rem;
    }
    .home .contactUs .content h3 {
        margin-bottom: 5rem;
    }
    .home .whatWeDo .content .control_text {
        padding: 24px 0px 24px !important;
    }
}

@media (max-width: 480px) {
    .home .whyChooseIPD .content{
        flex-direction: column;
        row-gap: 2rem;
    }
    .home .howItWorks .content,
    .home .advertiseYourConcept .content
    {
        flex-direction: column;
        row-gap: 5rem;
        align-items: center;

    }
    .home .howItWorks .item{
        flex-direction: column;
    }
    .home .howItWorks .item:after{
        content: initial;
    }
    .home .advertiseYourConcept .item {
        margin: 0;
        text-align: center;
    }
    .home .advertiseYourConcept h2 {
        font-size: 3rem;
    }
    .home .advertiseYourConcept .item {
        width: 60%;
        height: unset;
    }
    .home .howItWorks{
        padding: 4rem 0;
    }
    .home .contactUs .content p {
        font-size: 2.5rem;}
}
