.home {

  .whatWeDo {
    padding-top: 9.3rem;
    background-color: #FFFFFF;

    .content {
      display: flex;
      align-items: start;

      .aside {
        width: 100%;
        //background-color: #F7F7F7;
        display: flex;
        align-items: center;
        justify-content: center;

        > div {
          max-width: 66rem;

        }
      }

      h4, span {
        font-style: normal;
        font-weight: 600;
        font-size: 3.6rem;
        line-height: 5.4rem;

        color: #1B105A;
        margin-bottom: 11rem;

      }

      h4 span {
        color: #8FC54D;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 2.0rem;
        line-height: 3.0rem;
        color: #000000;
      }

      .control_text {
        padding: 54px 0px 24px !important;
      }

      button {
        margin-top: 2.2rem;
        padding: 1.2rem 3.2rem;
        border: 1px solid #8FC54D;
        border-radius: 2.2rem;

        font-style: normal;
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 1.9rem;

        color: #8FC54D;

      }
    }
  }

  .whyChooseIPD {
    padding: 4rem 0 6.1rem;
    background: #FFFFFF;

    .content {
      width: max-content;
      margin: 8rem auto 0;
      display: flex;
      align-items: start;
      justify-content: space-between;
    }

    h3 {
      margin-bottom: 8rem;
    }

    .item {
      position: relative;
      max-width: 31.6rem;
      margin-right: 12rem;

      &:last-child {
        margin: 0;
      }

      img {
        margin: 0 auto 3.3rem;
      }

      h5 {
        font-weight: 600;
        font-size: 2rem;
        line-height: 3rem;
        text-align: center;

        color: #3E4343;
        margin-bottom: 2.4rem;
      }

      p {
        font-size: 2rem;
        line-height: 3rem;
        text-align: center;
        color: #565656;
      }
    }
  }

  .howItWorks {
    padding: 4.7rem 0 9rem;
    background-color: #DEDFE0;

    h3 {
      margin-bottom: 6.4rem;

      &:after {
        content: initial;
      }
    }

    .content {
      display: flex;
      justify-content: center;

    }

    .item {
      position: relative;
      text-align: center;
      display: flex;
      align-items: center;

      &:first-child .body > div {
        border: 2px solid #8FC54D;
        box-shadow: 0px 0px 11px #8FC54D;
      }

      .body {
        > div {
          width: 16.4rem;
          height: 16.4rem;
          background-color: #fff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      &:after {
        position: relative;
        content: '';
        width: 188px;
        height: 3px;
        display: block;
        background-color: #8FC54D;
        margin: 0 3.3rem;
      }

      &:last-child:after {
        content: initial
      }


      img {

      }

      p {
        font-weight: 600;
        font-size: 2.4rem;
        line-height: 3.6rem;
        text-transform: uppercase;
        color: #565656;
        margin-top: 3.3rem;
      }
    }
  }

  .advertiseYourConcept {

    max-width: 94.7rem;
    margin: 5.3rem auto 0;

    h2 {
      margin-bottom: 8.8rem;
      font-family: Poppins-Regular !important;
      font-size: 4rem;
      line-height: 6rem;
      text-align: center;
      font-weight: 400;
      color: #000000;
    }

    .content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 5.3rem 0 6.5rem;
    }

    .item {
      width: 40%;
      margin-bottom: 4rem;
      margin-right: 2rem;
      height: 238px;
      display: flex;
      justify-content: space-between;
      align-items: start;
      flex-direction: column;


      &:nth-child(2n) {
        margin-right: 0;
      }

      &:nth-last-child(-n+2) {
        margin-bottom: 0;
      }

      h5 {
        font-weight: 500;
        font-size: 2rem;
        line-height: 3rem;

        color: #1B105A;
        margin-bottom: 1.8rem;
      }

      p {
        font-weight: 400;
        font-size: 2rem;
        line-height: 3rem;

        color: #565656;
        min-height: 10rem;
        margin-bottom: 4.6rem;
      }

      button {
        padding: 1.2rem 3.2rem;
        border: 1px solid #8FC54D;
        border-radius: 2.2rem;
        font-style: normal;
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 1.9rem;
        color: #8FC54D;
      }

    }
  }

  .contactUs {
    position: relative;

    img {
      width: 100%;
    }

    .content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      max-width: 79.4rem;
      margin: auto;
      height: 100%;
      display: flex;
      align-items: center;

      > div {
      }

      h3 {
        margin-bottom: 19rem;

        &:after {
          content: initial;
        }
      }

      p {
        font-weight: 600;
        font-size: 3.2rem;
        line-height: 3.9rem;
        text-align: center;

        color: #FFFFFF;
      }
    }
  }
}
.margin_top_8{
  margin-top: 8px;
}
.margin_top_24{
  margin-top: 24px;
}
.margin_top_32{
  margin-top: 32px;
}
