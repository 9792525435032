header {
  background-color: #fff;

  .navbar {
    display: flex;
    align-items: center;
    padding: 2rem 7rem;
    justify-content: space-between;
  }

  .nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .nav-container_search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .nav-container_search_b {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .header_search_b {
    position: relative;
    margin: 0px 24px;
    max-width: 1334px;
    width: 100%;
    border-bottom: 1px solid #756DA3;
  }

  .nav_menu_header_search_b {
    display: none;
  }

  .header_search_b input {
    padding-left: 35px;
    border: none !important;
  }

  .header_search_b input::placeholder {
    color: #565656;
    font-size: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .header_search_b img {
    position: absolute;
    top: 17%;
  }

  .main-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
  }

  .nav-logo {
    color: #f5b921;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    flex-grow: 1;
  }

  .nav-menu {
    > div {
      display: flex;
      align-items: center;
    }
  }

  .fa-code {
    margin-left: 1rem;
  }

  .nav-item {
    margin-right: 5rem;
    justify-content: center;
    padding: 0 .7rem;

    a {
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: #3E4343;
    }
  }

  .nav-item:after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
  }

  .nav-item:hover:after {
    width: 100%;
    background: #8FC54D;
  }

  .nav-item .link.active {
    color: #8FC54D;
  }

  .nav-icon {
    display: none;
  }

  .btn {
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    padding: 1.2rem 3.2rem;
    border-radius: 13rem;
    background: #8FC54D;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: #fff;
  }

  .searchContent {
    display: flex;
    margin-right: 5.3rem;
  }

  .authContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 13px;

    p {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      /* identical to box height */


      color: #000000;


    }

    > img {
      border-radius: 50%;
    }

    .btn {
      padding: 0;
      background-color: transparent;
      max-width: 32px;
      width: 100%;

      > img {
        border-radius: 50%;
      }
    }
  }
}

@media (max-width: 1900px) {
  header .nav-item {
    margin-right: 4rem;
  }
}

@media (max-width: 1800px) {
  header .nav-item {
    margin-right: 2.7rem;
  }
}

@media (max-width: 1700px) {
  header .navbar {
    display: flex;
    align-items: center;
    padding: 2rem 4rem;
  }
  header .nav-item {
    margin-right: 2rem;
  }

}

@media (max-width: 1600px) {

  header .searchContent {
    margin-right: 3rem;
  }
  header .nav-item {
    margin-right: 1.5rem;
  }
}

@media (max-width: 1500px) {
  header .nav-container {
    flex-direction: column;
  }
  .nav-container_search_b {
    flex-direction: column;
  }

  header .navbar {
    padding: 2rem 3rem;
  }
  .nav-menu {
    margin-top: 3rem;
  }
}

@media (max-width: 1400px) {
  header .btn {
    //font-size: 1.2rem;
    font-size: 2rem;
    //padding: 1rem;
    padding: 2rem;
  }
  header .searchContent {
    margin-right: 2rem;
  }
  header .nav-item a {
    font-size: 1.4rem;
  }
}

@media (max-width: 992px) {
  header {
    .navbar {
      padding: 1.6rem 1.5rem;

      .nav-logo {
        margin: 0;
      }
    }
  }
}

@media screen and (max-width: 885px) {
  header .authContent p {
    color: #b9b6b6;
  }
  header .nav-item a {
    color: #b9b6b6;
    font-weight: 500;
    font-size: 16px;
  }
  header {
    background-color: #101820;

    .nav-container {
      flex-direction: row;
      padding: 2rem 3rem;
    }

    .header_search_b {
      display: none;
    }

    .nav_menu_header_search_b {
      display: block;
      width: 100%;
      position: relative;
      margin: 0px 24px 16px;
      max-width: 300px;
      background: transparent;
      border-bottom: 1px solid #756DA3;
    }

    .nav_menu_header_search_b input {
      padding: 15px 15px 15px 35px;
      border: none !important;
      background: transparent;
      color: white;
      font-size: 16px;
    }

    .nav_menu_header_search_b input::placeholder {
      color: #565656;
      font-size: 16px;
      font-family: Inter;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .nav_menu_header_search_b img {
      position: absolute;
      top: 17%;
    }
    header .authContent, .nav-container_search_b_mobile .authContent{
     margin-top: 24px;
    }
    header .authContent p, .nav-container_search_b_mobile .authContent p{
     color: #b9b6b6;
    }

    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-top: 1px solid #fff;
      position: fixed;
      left: -110%;
      opacity: 1;
      transition: all 0.5s ease;
      height: 100vh;
      top: 0;

      &.active > div {
        flex-wrap: wrap;
        justify-content: center;

        .nav-item {
          width: 100%;
          color: white;
        }
      }
    }
    header .nav-item a {
      color: white !important;
    }

    .navbar .nav-logo img {
      max-width: 120px;
    }

    .nav-menu.active {
      background: #000;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 2;
      margin-top: 0;
      display: flex;
      justify-content: center;

      > div {
      }

    }

    .nav-item {
      margin-right: 0;
      text-align: center;
      margin-bottom: 3rem;

      &.active {
        border: none;
      }
    }

    .nav-icon {
      max-width: 25px;
      display: block;
      cursor: pointer;
      z-index: 9;
      position: fixed;
      right: 3rem;
    }

    .nav-icon_search {
      max-width: 25px;
      display: block;
      cursor: pointer;
      z-index: 9;
      position: fixed;
      right: 3rem;
      top: 5rem !important;
    }

    .nav-container_search_b_mobile {
      display: flex;
      flex-direction: column;
    }
  }
}
@media(max-width: 660px){
  header .nav-menu {
    display: none;
  }
}
@media(max-width: 360px){
  header .nav_menu_header_search_b {
    max-width: 260px;
    width: 100%;
  }
}