table {
  width: 100%;
  max-width: 922px;
  border-collapse: collapse;
  margin: 50px auto;
}


thead {
  background: #F2F2F2;
  /* line color */

  border-bottom: 1px solid #DEDFE0;
  border-radius: 8px;
}

td, th {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  color: #332486;
  padding: 13px 26px;
  border-bottom: 1px solid #DEDFE0;
}

td {
  border: 1px solid #DEDFE0;

  color: #555655;
}

/*
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {
table{
  max-width: 100%;
  padding: 5rem 3rem;
}
  td {
    padding: 8px;
  }
  table {
    width: 100%;
  }

  /* Force table to not be like tables anymore */
  table, thead, tbody, th, td, tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    border: 1px solid #ccc;
    margin-bottom: 30px;
  }

  td {
    /* Behave  like addData "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }

  td:before {
    /* Now like addData table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    /* Label the data */
    content: attr(data-column);

    color: #000;
    font-weight: bold;
  }

}
