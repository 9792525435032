․pagination_cont {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 350px;
    width: 100%;
    list-style-type: none !important;
    cursor: pointer;
    margin: auto;
}

.pagination li a {
    width: 40px;
    height: 40px;
    font-size: 18px;
    font-weight: 700;
    /*border-radius: 50%;*/
    /*background: #F5F5FF;*/
    display: flex;
    justify-content: center;
    align-items: center;
}

.selected {
    background: #8FC54D;
    border-radius: 50%;
    color: white !important;
    font-weight: 600;
}
.selected.pagination li a  {
    color: white !important;
}

.pagination li a:hover {
    background: #8FC54D;
    border-radius: 50%;
}

.pagination .previous a, .pagination .next a {
    background: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    color: black;
    border: 1px solid grey;
}
.pagination .previous{
    margin-right: 5px;
}
.pagination .next{
    margin-left: 5px;
}