.policy_cont{
    max-width: 1322px;
    padding: 0px 16px;
    width: 100%;
    margin: 110px auto;
}
.policy_cont_b1{
    margin: 0px auto 125px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    max-width: 355px;
    width: 100%;
}
.policy_cont_b1 div{
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
}
.policy_cont_b1 span, .policy_cont_b1 a{
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
}
.policy_cont_b1 a{
    text-decoration: underline;
}
.policy_cont_b2{
    max-width: 350px;
    width: 100%;
    text-align: left;
    margin-bottom: 24px;
}
.policy_cont_b2 p{
    width: 100%;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
}
.policy_cont h2{
    color: #332486;
    font-size: 24px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.policy_cont_b3{
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: justify;
}
.policy_cont_b3_t1{
    margin-left: 5%;
    margin-top: 16px;
    margin-bottom: 16px;
}
.policy_cont_b3_t1 span{
    font-weight: 600;
}
.policy_cont_b3_t2 span{
    font-weight: 600;
}
.policy_cont_b3_t3{
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 1.5%;
}
.policy_cont_b3_t3 li span{
    font-weight: 600;
}
.policy_cont_b3_t5{
    margin: 16px 0px;
}
.policy_cont_b3_t5 span{
    margin-left: 5%;
    font-weight: 600;
}
.policy_cont_b3_t7{
    margin: 16px 0px;
}
.policy_cont_b3_t7 ol{
    margin-left: 1.5%;
}
.policy_cont_b3_t8{
    margin-top: 16px;
}
.policy_cont_b3_t9{
    margin: 16px 0px;
}
.policy_cont_b3_t11{
    margin: 16px 0px;
}
.policy_cont_b3_t11 span{
    margin-left: 5%;
    font-weight: 600;
}
.policy_cont_b3_t12{
    margin: 16px 0px;
}
.policy_cont_b3_t12 span{
    font-weight: 600;
}
.policy_cont_b3_t13 ul{
    list-style: unset !important;
    margin-left: 1.5%;
}
.policy_cont_b3_t14{
    margin: 16px 0px;
}
.policy_cont_b3_t14 span{
    font-weight: 600;
    margin-right: 5px;
}
.policy_cont_b3_t15:first-child{
    margin-top: 16px;
}
.policy_cont_b3_t15 span{
   font-weight: 600;
}
.policy_cont_b3_t16{
    margin-top: 48px;
}
.policy_cont_b3_t16 span{
 font-weight: 600;
}
.policy_cont_b3_t17{
    display: flex;
    flex-direction: column;
    font-weight: 600;
}
@media(max-width: 768px){
    .policy_cont {
        margin: 30px auto 50px;
    }
    .policy_cont_b1 {
        margin: 0px auto 24px;
        font-size: 1.6rem;
    }
    .policy_cont_b1 div{
        font-size: 16px;
    }
    .policy_cont_b1 span, .policy_cont_b1 a{
        font-size: 16px;
    }
    .policy_cont_b2 p{
        font-size: 16px;
    }
    .policy_cont h2 {
        font-size: 18px;
    }
    .policy_cont_b3_t16 {
        margin-top: 24px;
    }
    .policy_cont_b2 {
        margin-bottom: 16px;
    }
}
@media(max-width: 390px){
    .policy_cont {
        margin: 24px auto 24px;
    }
}