.proposal {
  h1 {
    background: #332486;
    padding: 2.4rem 3rem;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 3.2rem;
    line-height: 3.9rem;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 19rem;
  }

  .body {
    width: 100%;
    max-width: 104.6rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 3.4rem;
    padding: 0 3rem;
    margin: 0 auto 17.3rem;


    .btnContent {
      width: 100%;
      margin-top: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 96px;
    }

    .btn {
      display: block;
      margin: 0;
      color: #FFFFFF;
      max-width: 11.9rem;
      width: 100%;
      background: #332486;
      border-radius: 2.9rem;
      padding: 1.2rem 3.2rem;

      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 1.9rem;
      &.light{
        background-color: transparent;
        border: 1px solid #3E4343;
color: #000;
      }
    }

    .checkboxContent {
      width: 100%;
      max-width: 39.5rem;
      display: flex;
      align-content: center;

      label {
        width: max-content;
        display: flex;
        align-items: center;
        margin-right: 3.1rem;

        &:last-child {
          margin: 0;
        }

        input {
          width: max-content;
          margin-right: 1.6rem;
        }
      }
    }
    .error_div{
      width: 100%;
      height: 30px;
      align-items: center;
      justify-content: center;

      p{
        margin-top: 60px;
        color: red
      }
    }
    .chat_item{
      width: 100vh;
      margin-top: 10px;
      display: flex;

    }
    .chat_item_left{
      margin-top: 10px;
      width: 100vh;
      display: flex;
      justify-content: end;
    }
    .chat_text{
      color: #000;
    }
  }
}
