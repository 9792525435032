.cancel_request_cont{
    max-width: 966px;
    width: 100%;
    margin: 90px auto 133px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.cancel_request_cont_fields{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.cancel_request_cont_fields .formGroup{
   max-width: 395px !important;
    width: 100% !important;
}
.cancel_request_cont_fields .formGroup:last-child{
   margin-left: 100px;
}
.accepted_request_cont_fields .formGroup input{
   background: white;
}
.cancel_request_cont_fields .formGroup_item{
   margin-bottom: 32px !important;
}
.cancel_request_cont .radio_btns_b{
    display: flex;
    align-items: center;
    margin-top: 16px;
}
.radio_btns_b .ddIpContent_b1_item{
    white-space: nowrap;
}
.radio_btns_b .ddIpContent_b1_item:last-child{
   margin-left: 32px;
}
.cancel_request_btn_b{
    margin-top: 38px;
}
.cancel_request_btn_b .cancel_request_btn{
    border: 1px solid #332486;
    border-radius: 29px;
    padding: 12px 32px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #332486;
}