.agreement_proposal_cont {
    display: flex;
    flex-direction: column;
    max-width: 1497px;
    width: 100%;
    height: max-content;
    margin: 185px auto 0;
    padding: 0px 16px;
}

.agreement_proposal_item {
    width: 100%;
    background: white;
    height: max-content;
    padding: 23px 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 80px;
}
.agreement_proposal_item:last-child{
    margin-bottom: 0px;
}

.agreement_proposal_item_b1 {
    display: flex;
    align-items: center;
}
.agreement_proposal_item_b1 span{
    white-space: nowrap;
    font-family: 'Roboto' !important;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #332486;
}
.agreement_photo_b{
    box-shadow: 1px 1px 11px rgba(51, 36, 134, 0.2);
    max-width: 220px;
    width: 100%;
    margin-right: 109px;
}
.agreement_photo_b img{
    width: 100%;
}
.agreement_proposal_btns_b button{
    border: 1px solid #8FC54D;
    border-radius: 29px;
    padding: 10px 24px;
    font-family: 'Roboto' !important;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #3E4343;
}
.agreement_proposal_btns_b button:last-child{
   margin-left: 24px;
}
.modal_inner{
    padding: 0px 13px;
}
.ReactModal__Overlay--after-open{
    background: rgba(62, 67, 67, 0.5) !important;
}
.modal_inner_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}
.modal_inner_header p{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
}
.modal_inner_header img{
  cursor: pointer;
}
.modal_inner .text{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}
.cancel_btn_b{
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 32px auto 0;
}
.cancel_btn_b button{
    background: #332486;
    border-radius: 29px;
  padding: 12px 32px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
}
@media(max-width: 1024px){
    .agreement_photo_b {
        margin-right: 16px;
    }
    .agreement_proposal_btns_b{
        margin-left: 16px;
    }
}
@media(max-width: 850px){
    .modal_inner{
        padding: 16px;
    }
}