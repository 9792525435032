.select_subscription_cont {
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    margin-top: 42px;
    color: #464343;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    line-height: 41.6px; /* 130% */
  }
}

.subscriptions_blocks {
  margin: 30px auto;
  display: flex;
  max-width: 1140px;
  width: 100%;
  justify-content: space-between;
}

.subscription_block {
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 24px 14px 16px;
  border: 1px solid #E8E7E7;
  background: #FFF;
  height: 443px;
  margin-right: 16px;

  .subscription_block:last-child {
    margin-right: 0px;
  }

  p {
    color: #464343;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .desc1 {
    text-align: center;
    margin-top: 65px;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
  }

  .desc2 {
    text-align: center;
    margin-top: 24px;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
  }

  .price {
    text-align: center;
    margin-top: 58px;
    color: #332486;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .currency{
    color: #1B105A;
  }

  button {
    margin: 0px auto;
    border-radius: 30px;
    border: 1px solid #332486;
    background: #FFF;
    color: #332486;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    width: 100%;
    padding: 8px;
  }
}
.subscription_block:hover{
  transform:scale(1.02);
}