//Black 100
@font-face {
  font-family: Roboto-Regular;
  src: url('../../assets/fonts/Roboto/Roboto-Regular.ttf');
}
/*
//Black 200
@font-face {
  font-family: NunitoSans-ExtraLight;
  src: url('../../assets/fonts/Nunito_Sans/NunitoSans-ExtraLight.ttf');
}


//Light 300
@font-face {
  font-family: NunitoSans-Light;
  src: url('../../assets/fonts/Nunito_Sans/NunitoSans-Light.ttf');
}

//Regular 400
@font-face {
  font-family: NunitoSans-Regular;
  src: url('../../assets/fonts/Nunito_Sans/NunitoSans-Regular.ttf');
}


//SemiBold 500
@font-face {
  font-family: Montserrat-Medium;
  src: url('../../assets/fonts/Montserrat/Montserrat-SemiBold.ttf');
}

//SemiBold 600
@font-face {
  font-family: Montserrat-SemiBold;
  src: url('../../assets/fonts/Montserrat/Montserrat-SemiBold.ttf');
}

//Bold 700
@font-face {
  font-family: NunitoSans-Bold;
  src: url('../../assets/fonts/Nunito_Sans/NunitoSans-Bold.ttf');
}

//ExtraBold 800
@font-face {
  font-family: NunitoSans-ExtraBold;
  src: url('../../assets/fonts/Nunito_Sans/NunitoSans-ExtraBold.ttf');
}

//Black 900
@font-face {
  font-family: NunitoSans-Black;
  src: url('../../assets/fonts/Nunito_Sans/NunitoSans-Black.ttf');
}
*/