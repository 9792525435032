.agrrement_cont {
  width: 100%;
  max-width: 882px;
  padding: 4.7rem 1rem 1.8rem;
  margin: auto;
  position: relative;

  .agrrement_cont_header {
    h2 {
      font-weight: 700;
      color: #000;
      font-size: 24px;
      line-height: 36px;
      max-width: 700px;
      width: 100%;
      margin: 0px auto 2px;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      color: #000000;
    }

  }

  .agrrement_cont_body {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 4.2rem;


    > div span {
      font-weight: 700;
    }

    > div a {
      font-weight: 700;
      text-decoration: underline;
      margin-right: 3px;
    }

    h5 {
      font-weight: 700;
      color: #000;
      font-size: 16px;
      line-height: 24px;
    }

    h4 {
      font-weight: 700;
      color: #000;
      font-size: 18px;
      line-height: 27px;
    }

    h3 {
      font-weight: 700;
      color: #000;
      font-size: 24px;
      line-height: 36px;
      border-bottom: none !important;
      margin-bottom: 0px !important;
    }

    h3:after {
      display: none;
    }

    h2 {
      font-weight: 700;
      color: #000;
      font-size: 32px;
      line-height: 48px;
    }

    input[type="checkbox"] {
      width: 16px;
      height: 16px;
      background: transparent;
      border: 1px solid #756DA3;
      margin-right: 12px;
    }

    label {
      font-size: 16px;
      line-height: normal;
      max-width: unset;
    }

    span {

    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      color: #000000;
    }

    .disclaimer {
      color: #CA0017;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px;
    }
  }


  .apply_btn {
    position: absolute;
    right: 0;
    width: max-content;
    background: #332486;
    border-radius: 142px;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.9rem;
    padding: 0.85rem 4.05rem;
    color: #FFFFFF;

  }
}

.continue_btn {
  width: 172px;
  height: 43px;
  border-radius: 29px;
  color: white;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  background: #959595;
}

#definitons {
  border: none !important;
  width: 100%;
  background: transparent;
}

#definitons td, #definitons th {
  padding: 8px;
  background: transparent !important;
  border: none !important;
  font-weight: 400;
  font-size: 16px;
}
#definitons td:first-child {
margin-right: 36px;
}

#definitons th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  color: #000;
}

.filled_data_input {
  max-width: 298px;
  width: 100%;
}

.filled_data_input input {
  width: 100%;
  display: inline-flex;
  padding: 8px 16px;
  align-items: center;
  border-color: transparent;
  border-bottom: 2px solid #8FC54D;
  background: #DDEEC9;
  color: #000000;
}

.filled_data_input input::placeholder {
  color: #000000;
  text-transform: none !important;
}

.agreement_sell_form_group label:before {
  border-radius: 50%;
}

textarea {
  padding: 12px;
}

ul.obligation_List {
  list-style-type: square;
  margin-left: 24px;
  padding: 0;
}

.propertation_inputs_b {
  max-width: 377px;
  width: 100%;
  margin-bottom: 36px;
  align-items: end;
}

.propertation_inputs_b div {
  width: 173px;
}

.title_b {
  width: 100%;
}

.title_b span {
  width: 100%;
  height: 1px;
  background: #000;
}

.title_b h5 {
  margin: 0px 12px;
  min-width: max-content;
}

.green_div_border {
  width: 100%;
  border-bottom: 2px solid var(--Green, #8FC54D);
  background: #DDEEC9;
  padding: 8px 16px;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.scroll_top_icon {
  position: fixed;
  right: 2%;
  bottom: 3%;
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.QR_code_b{
  max-width: 190px;
  width: 100%;
}
.QR_code_b img{
  width: 100%;
}
.checkboxContent_checkboxes div{
  margin-right: 32px;
}
.partnership_list{
  list-style: square;
}
.partnership_list li{
margin-left: 24px;
}