.settingsContainer {
  max-width: 149.7rem;
  margin: auto;
  padding: 6.5rem 0 29.7rem;

  .header {
    display: flex;
    padding: 0px 16px;
    column-gap: 20px;
    margin-bottom: 7.5rem;

    .nav-item {
      width: max-content;
      background: #FFFFFF;
      border: 1px solid #C3C3C3;


    }

    .link {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 23px;
      text-align: center;
      color: #332486;
      padding: 12px 65px;
      height: 47px;
    }

    [aria-current="page"] {
      border: 1px solid #332486;
      font-weight: 600;
    }
  }

  .selected {
    display: block;
  }

  .subscrptonContent {
    > .body {
      //display: flex;
      //column-gap: 30px;
      //justify-content: space-between;
      max-width: 1290px;
      width: 100%;
      display: grid;
      grid-template-columns:  1fr 1fr 1fr 1fr;
      grid-gap: 30px;
      padding: 0px 16px;
    }

    .subscrptonItem {
      width: 100%;
      max-width: 300px;
      padding: 24px 14px 16px;
      background: #FFFFFF;
      border: 1px solid #E8E7E7;
      height: 443px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      text-align: center;

      h4 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 23px;
        font-family: sans-serif;

        color: #464343;

      }

      > .body {
        height: 209px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .description {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          text-align: left;
          /* identical to box height */
          font-family: sans-serif;
          color: #464343;
        }

        .price {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 40px;
          line-height: 19px;
          font-family: sans-serif;

          color: #332486;

          span {

            font-size: 16px;
            line-height: 67px;

            &:last-child {
              margin-left: 4px;
            }
          }
        }
      }

      button {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        /* identical to box height */
        font-family: sans-serif;
        color: #332486;
        background: #FFFFFF;
        border: 1px solid #332486;
        border-radius: 30px;
        padding: 8px 30px;
        width: 100%;
      }
    }

    .btns {
      display: flex;
      justify-content: space-between;
      column-gap: 90px;
      width: max-content;
      margin: 109px auto 0;

      button {
        &.cancel {
          color: #3E4343;
          border-color: #565656;
          background-color: transparent;

        }

        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        padding: 10px 31px;
        color: #FFFFFF;
        background: #332486;
        border-radius: 29px;
        border: 1px solid #332486;
        text-transform: capitalize;
        font-family: sans-serif;
      }
    }
  }

  .billingContent {
    background-color: #fff;
    border: 1px solid #E8E7E7;
    padding: 32px 75px 82px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    column-gap: 82px;


    h5 {
      width: 100%;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 24px;
      color: #000000;
    }

    > button {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      height: max-content;
      color: #FFFFFF;
      background: #332486;
      border-radius: 29px;
      padding: 10px 31px;
      align-self: flex-end;
      display: flex;
      margin-top: 109px;
    }

    h6 {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
      margin-bottom: 21px;
    }


    p {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;

      color: #464343;
    }

    > div {
      width: calc(50% - 41px);
      padding: 20px 30px 40px;
      border-radius: 4px;
      border: 1px solid #332486;

      .cardBody {
        display: flex;
        justify-content: space-between;

        img {
          margin-right: 18px;
        }

        span {
          display: flex;
          align-items: center;

          h6 {
            margin-bottom: 0;
          }

          button {
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #000000;
            padding: 0 8px;

            &:first-child {
              margin-right: 5px;
            }
          }
        }
      }


    }
  }

  .passwordContent {
    background-color: #fff;
    padding: 30px;
    column-gap: 82px;
  }

  .passwordContent_inner {
    max-width: 1000px;
    width: 100%;

  }

  .passwordContent_inner_block1 {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .passwordContent_inner_block1 div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 24px;
  }

  .passwordContent_inner_block1 div label:first-child {
    margin-right: 16px;
  }

  .password_btns_b {
    display: flex;
    justify-content: end;
    align-items: center;
  }

  .passwordContent .profile_btns_b {
    margin-top: -4px !important;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
  }

  .passwordContent .saveBtn {
    margin-top: 0px !important;
    padding: 12px 32px !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    background: #332486;
    border-radius: 21px;
  }

  .passwordContent .discardBtn {
    border: 1px solid #332486;
    border-radius: 29px;
    padding: 12px 32px !important;
    margin-right: 24px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #332486;
  }

  .profileContent {
    background-color: #fff;
    padding: 57px 98px 25px;

    .body {
      max-width: 44.8rem;
    }

    .headContent {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      column-gap: 40px;
      margin-bottom: 57px;

      img {
        border-radius: 8px;

      }

      button {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        /* identical to box height */
        font-family: sans-serif;
        border: 1px solid #000000;
        border-radius: 25px;
        color: #3E4343;
        padding: 7px 28px;
      }
    }

    .formGroup {

      label {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        /* identical to box height */
        margin-bottom: 28px;
        display: block;
        color: #565656;
      }

      input {
        width: 100%;
        margin-top: 2px;
        padding: 10px 15px;
        background: #FFFFFF;
        border: 1px solid rgba(51, 36, 134, 0.7);
        border-radius: 4px;
      }

    }

    .saveBtn {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      /* identical to box height */


      color: #FFFFFF;
      padding: 7px 39px;
      background: #332486;
      border-radius: 21px;
      margin-top: 40px;
    }
  }

  .profileContent {
    padding: 30px !important;
  }

  .profileContent .body {
    max-width: 936px !important;
    width: 100%;
  }

  .profileContent .headContent {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 24px !important;
  }

  .profileContent .headContent img {
    border-radius: 50% !important;
    width: 120px !important;
    height: 120px !important;
  }

  .profileContent .headContent .change_pic, .profileContent .headContent .remove_pic, .upload_pic {
    border: none;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #332486;
    padding: unset !important;
    display: flex;
    align-items: center;
  }

  .profileContent .headContent .change_pic:before {
    content: url("../../../../assets/images/edit_icon_blue.png") !important;
    margin-right: 11px;
  }

  .profileContent .headContent .remove_pic:before {
    content: url("../../../../assets/images/clear_icon_blue.png") !important;
    margin-right: 11px;
  }

  .profileContent .headContent .upload_pic:before {
    content: url("../../../../assets/images/icons/file_upload_black_24dp 1.png") !important;
    margin-right: 11px;
  }

  .profile_formGroupBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    max-width: 936px !important;
    width: 100% !important;
  }

  .profileContent .formGroup {
    max-width: 448px !important;
    width: 100% !important;
    margin-right: 40px !important;
  }

  .profileContent .formGroup label {
    margin-right: 0px !important;
    max-width: unset !important;
  }

  .profileContent .formGroup input {
    width: 100% !important;
  }

  .profileContent .formGroup:last-child {
    margin-right: 0px !important;
  }

  .profileContent .profile_btns_b {
    margin-top: -4px !important;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
  }

  .profileContent .saveBtn {
    margin-top: 0px !important;
    padding: 12px 32px !important;
  }

  .profileContent .discardBtn {
    border: 1px solid #332486;
    border-radius: 29px;
    padding: 12px 32px !important;
    margin-right: 24px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #332486;
  }


  ///////////////


}


//************** Modal

.modal.addCardModal {
  .modalContent {

  }

  .modalBody {
    h5 {
      margin-bottom: 90px;

    }

    .saveCard {
      margin-bottom: 0;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 48px;
    }

    .cardsContent {
      margin-bottom: 0;
      column-gap: 20px;
      align-self: end;
      min-height: 44px;

      img {
        width: calc(33% - 12px);

      }
    }

    .cardNumberContent {
      column-gap: 40px;

      label {
        max-width: 300px;
      }
    }
  }

  .expirationContent {
    column-gap: 24px;
  }

  select {
    margin-bottom: 0;
  }

  .btnsContent {
    width: 373px;
    margin: auto;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 31px;

      width: 162px;
      //height: 48px;

      /* blue */

      background: #332486;
      border-radius: 29px;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      text-align: center;

      color: #FFFFFF;


      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 0;
    }

    .light {
      color: #000;
      border: 1px solid #565656;
      background-color: transparent;
    }
  }
}

.btn-file {
  position: relative;
  overflow: hidden;
}

.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

//************** Modal

@media (max-width: 950px) {
  .settingsContainer .header .link {
    padding: 12px 45px;
  }
  .settingsContainer .subscrptonContent > .body {
    grid-template-columns:  1fr 1fr;
    gap: 24px;
    padding: 0px 16px;
    justify-items: center;
  }
  .settingsContainer .subscrptonContent .subscrptonItem {
    padding: 16px;
    height: 400px;
  }
  .settingsContainer .billingContent {
    padding: 32px;
  }

}

@media (max-width: 800px) {
  .settingsContainer {
    padding: 6.5rem 0 10.7rem;
  }
  .settingsContainer .header .link {
    padding: 12px 25px;
    font-size: 2rem;
  }
  .settingsContainer .billingContent {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .settingsContainer .billingContent > div {
    margin-bottom: 16px;
    padding: 16px 16px 32px;
    max-width: 400px;
    width: 100%;
  }
  .settingsContainer .billingContent > button {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    height: max-content;
    color: #FFFFFF;
    background: #332486;
    border-radius: 29px;
    padding: 10px 31px;
    align-self: center;
    display: flex;
    margin-top: 12px;
    font-size: 16px;
  }
  .modal.addCardModal .modalBody h5 {
    margin-bottom: 30px;
  }
  .modal.addCardModal .modalBody div {
    margin-bottom: 24px;
  }
  .modal.addCardModal .btnsContent .light {
    font-size: 16px;
  }
  .modal.addCardModal .btnsContent button {
    font-size: 16px;
  }
  .settingsContainer .profileContent .formGroup {
    margin-right: 0px !important;
  }
}
@media(max-width: 549px){
  .settingsContainer .password_btns_b {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .settingsContainer .passwordContent_inner_block1 {
    justify-content: center;
    width: 100%;
    margin-bottom: 16px;
  }
  .settingsContainer .passwordContent_inner_block1 label{
    margin-bottom: 16px;
  }
  .settingsContainer .passwordContent_inner_block1 div {
    margin-bottom: 0px;
  }
  .settingsContainer .passwordContent .discardBtn {
    padding: 12px !important;
  }
}

@media (max-width: 500px) {
  .settingsContainer .header .link {
    padding: 12px;
  }
  .settingsContainer .subscrptonContent > .body {
    grid-template-columns:  1fr;
    gap: 16px;
  }
  .settingsContainer .subscrptonContent .subscrptonItem h4 {
    font-size: 18px;
    line-height: 22px;
  }
  .settingsContainer .subscrptonContent .subscrptonItem {
    padding: 16px;
    height: max-content;
  }
  .settingsContainer .subscrptonContent .subscrptonItem > .body {
    height: max-content;
    padding: 16px 0px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .settingsContainer .subscrptonContent .subscrptonItem > .body .description {
    text-align: center;
    padding-bottom: 16px;
  }
  .settingsContainer {
    padding: 6.5rem 0 6.7rem;
  }
}

@media(max-width: 480px) {
  .modal.addCardModal .btnsContent .light {
    margin-bottom: 16px;
  }
  .modal.addCardModal .modalBody .cardNumberContent {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .modal.addCardModal .modalBody .cardNumberContent label {
    width: 100%;
    max-width: unset;
  }
  .modal.addCardModal .modalBody .cardsContent {
    margin: 16px auto 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal.addCardModal .expirationContent {
    display: flex;
    flex-direction: column;
  }
  .modal.addCardModal .expirationContent label {
    max-width: unset;
    width: 100%;
    margin-bottom: 16px;
  }
  .modal.addCardModal .expirationContent label select {
    max-width: unset;
    width: 100%;
    margin-bottom: 16px;
  }
  .modal.addCardModal .modalBody div {
    margin-bottom: 16px;
  }
  .modal.addCardModal .btnsContent {
    width: unset;
    margin: auto;
  }
}

@media (max-width: 414px) {
  .settingsContainer .header .link {
    padding: 0px 12px;
    height: unset;
  }
  .settingsContainer .billingContent {
    padding: 16px;
  }
}

@media(max-width: 370px) {
  .settingsContainer .profileContent .headContent {
    display: flex;
    flex-direction: column;
  }
  .settingsContainer .profileContent .profile_btns_b {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .settingsContainer .profileContent .discardBtn {
    margin-bottom: 16px;
    margin-right: 0px;
    margin-top: 16px;
  }
  .settingsContainer .profileContent .formGroup label {
    margin-bottom: 16px;
  }
  .settingsContainer .profileContent {
    padding: 24px !important;
  }
}