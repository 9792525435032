.CertificatesViewApply_header {
  width: 100%;
  height: 87px;
  background: #332486;
  padding: 20px;
}

.view_certeficate {
  width: 100%;
  max-width: 100.2rem;
  padding: 4.7rem 3rem 1.8rem;
  margin: auto;
  position: relative;

  .body {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 34.2rem;

    > div {
    }

    h5 {
      font-weight: 700;
      color: #000;
      font-size: 24px;
      line-height: 36px;
    }

    input[type="checkbox"] {
      width: 16px;
      height: 16px;
      background: transparent;
      border: 1px solid #756DA3;
      margin-right: 12px;
    }

    label {
      max-width: unset;
      font-size: 16px;
      line-height: normal;
    }
  }

  .photoContent {
    width: 308px;
    height: 272px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 1px 11px rgba(51, 36, 134, 0.2);


    span {
      height: max-content;
      max-width: 30%;
      text-align: center;
    }
  }

  .apply_btn {
    position: absolute;
    right: 0;
    width: max-content;
    background: #332486;
    border-radius: 142px;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.9rem;
    padding: 0.85rem 4.05rem;
    color: #FFFFFF;

  }

  .settings {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: start;


    label {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 23px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #565656;

      &:first-child {
        margin-right: 3.2rem;
      }
    ;
    }

    input {
      margin-right: .6rem;
    }
  }

  p {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 2.0rem;
    line-height: 2.3rem;
    margin-bottom: 8px;
    color: #565656;

  }

  h5 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #565656;
  }

  .qrContent {
    max-width: 36.2rem;
    margin: auto;

    p {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 2.0rem;
      line-height: 2.3rem;
      margin-top: 1.4rem;
      color: #565656;
    }
  }
}

.d_flex {
  display: flex !important;
}

.fd_column {
  flex-direction: column;
}

.fd_row {
  flex-direction: row;
}

.justify_content_center {
  justify-content: center !important;
}
.justify_content_start {
  justify-content: start !important;
}


.space_beetween {
  justify-content: space-between !important;
}

.align_center {
  align-items: center !important;
}
.align_start {
  align-items: start !important;
}

.apply_certificate_item {
  display: flex;
  justify-content: space-between;
  align-items: center;


  .certificate_item {
    max-width: 377px;
    width: 100%;
    margin-top: 0px !important;
  }

  .certificate_item:first-child {
    margin-right: 16px;
  }
}

.margin_top_48 {
  margin-top: 48px;
}

.margin_top_16 {
  margin-top: 16px;
}

.margin_top_12 {
  margin-top: 12px;
}

.file_upload_img {
  position: absolute;
  right: 3%;
  //top: 40px;
}
.file_upload_img_{
  position: absolute;
  right: 3%;
  top:50%;
}

.form-group {
  display: block;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
}

.form-group label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #332486;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  width: 16px;
  height: 16px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 12px;
}

.form-group input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: 8px;
  width: 4px;
  height: 13px;
  border: solid #332486;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkboxes_group {
  max-width: 400px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.checkboxes_group .form-group {
  margin-left: 16px;
}

.submit_b {
  margin-left: auto;
  margin-right: auto;
}

.calendar_cont .calendar_block {
  max-width: 395px !important;
  width: 100%;
}

.calendar_cont .calendar_block img {
  top: 55%;
}

.calendar_cont .calendar_block:first-child {
  margin-right: 24px;
}

.file_upload label {
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid rgba(51, 36, 134, 0.60);
  background: #FFF;
  padding: 10px 20px;
}

#upload {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
#upload_val {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.radio_btn_b {
  width: max-content !important;
  margin-right: 32px;
}

.radio_btn_b input {
  margin-right: 8px;
}

.apply_certificate_item .calendar_block {
  max-width: 377px !important;
  width: 100%;
}

.line_signature {
  max-width: 377px;
  width: 100%;
  height: 1px;
  background: #332486;;
}

.checkbox_item label {
  font-size: 18px !important;
}

.checkbox_item label p {
  font-size: 18px !important;
  line-height: 17px !important;
}

textarea {
  border-radius: 4px;
  border: 1px solid var(--line, rgba(51, 36, 134, 0.60));
  background: #FFF;
}

.hr_line {
  width: 100%;
  height: 1px;
  background: #332486;
  margin: 24px 0px;
}

.input_container {
  position: relative;
  border-radius: 4px;
  border: 1px solid rgba(51, 36, 134, 0.60);
  background: #FFF;
  padding: 0.3rem 1.5rem;
}

.input_container label {
  width: 100% !important;
}

input[type=file]::file-selector-button {
  display: none;
}

.input_container label {
  border: none;
  padding: 0px;
  width: max-content;
  z-index: 1;
}

#files {
  display: none;
}

.tooltip-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}




