.itemsBanner {
  width: 100%;
  max-width: 135rem;
  margin: auto;
  row-gap: 7.2rem;
  //padding:  28.4rem 3rem 25.7rem;
  padding: 18.4rem 3rem 20.7rem;
  display: flex;
  flex-wrap: wrap;

  .item {
    margin-right: 3rem;
    width: 23.2%;

    &:nth-child(4n) {
      margin-right: 0;
    }

    button {
      width: max-content;
      position: absolute;
      background: #332486;
      border-radius: 142px;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 1.9rem;
      padding: .85rem 4.05rem;
      color: #FFFFFF;
      left: 0;
      right: 0;
      margin: auto;
      bottom: 4.3rem;
    }

    p {
      font-family: 'PoppinsMedum', sans-serif;
      font-size: 1.6rem;
      line-height: 2.4rem;

      color: #000000;
    }

    .photo {
      width: 30rem;
      height: 30rem;
      border: 1px solid #E0DBDB;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }

    .name {
      color: #3E4343;
      margin-top: 1rem;
    }

    .item {
      position: relative;
    }

    .view_btn {
      display: none;
      transition-delay: 2s;
    }

    .photo:hover {
      transform: scale(1.02);
    }

    .photo:hover .view_btn {
      display: block;

    }
  }
}
