
.notification {
  padding: 116px 0 826px;
}

table {
  background-color: #fff;
  margin: auto;

  th {
    background: #F2F2F2;
  }

  .status {
    text-align: center;
  }

  .status span {
    color: #332486;
    background-color: #F2F2F2;
    padding: 3.5px 18px;
    border-radius: 8px;

  }

  .statusRegistered span {
    color: #3A854F;
    background: #F7FFF6;
  }

  .statusProcessing span {
    color: #DA9800;
    background: #F3EEC1;
  }

  .statusPending span {
    color: #EA0D0D;
    background: #FFF4F4;
  }
}

@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {
  table .status {
    text-align: left;
  }
}

@media (max-width: 1200px) {
  .notification {
    padding: 110px 50px 500px;
  }
}

@media (max-width: 992px) {
  .notification {
    padding: 110px 30px;
  }
}

@media (max-width: 480px) {
  .notification {
    padding: 70px 15px;
  }
  td:before {
    font-size: 14px;
  }
}