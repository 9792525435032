ul{
  list-style: none;
}
a{
  text-decoration: none;
}
button{
  border: none;
  outline: none;
  background-color: transparent;
}
button:hover{
  transform: scale(1.03);
}

