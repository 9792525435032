.authorizedHeader {
  background-color: #332486;
  padding: 1rem 3rem;

  ul {
    display: flex;
    width: 100%;
    max-width: 1281px;
    margin: auto;
  }

  .nav-item {
    width: 100%;
    max-width: 23.6rem;
    margin: auto;
    text-align: center;

    a {
      font-style: normal;
      font-weight: 600;
      font-size: 2rem;
      line-height: 2.3rem;
      text-align: center;
      padding: 1.2rem 1.6rem;
      display: flex;
      justify-content: center;
      color: #FFFFFF;

    }
  }
}
.Link_active{
 color: #8FC54D !important;
  white-space: nowrap;
}
.link{
  white-space: nowrap;
}
@media(max-width: 768px){
  .authorizedHeader {
   max-width: 768px;
    width: 100%;
    overflow-x: auto;
  }
}

