.requestsContainer {
  max-width: 149.7rem;
  margin: auto;
  padding: 6.5rem 0 29.7rem;
}

.requestsContainer .header {
  display: flex;
  column-gap: 20px;
  margin-bottom: 18.5rem;
}

.requestsContainer .header div {
  width: max-content;
  background: #FFFFFF;
  box-shadow: 0 0 4px #332486;
  padding: 12px 65px;

}

.requestsContainer .header p {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  text-align: center;

  color: #332486;
}

.requestsContainer .body {

}

.requestsContainer .item {
  padding: 23px 42px;
  background: #FFFFFF;
  border: 1px solid #E8E7E7;
  margin-bottom: 8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.requestsContainer .imgContent {
  background: #FFFFFF;
  width: 220px;
  height: 195px;
  box-shadow: 1px 1px 11px rgba(51, 36, 134, 0.2);
}

.requestsContainer .imgContent p {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  max-width: 50%;
  color: #565656;

}

.requestsContainer h4 {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-align: center;

  color: #332486;
}

.requestsContainer button {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  padding: 8px 45px;
  border: 1px solid #8FC54D;
  border-radius: 29px;
}

