footer {
  padding: 7.4rem 3rem 1.5rem;
  background-color: #101820;
  .foot{
    max-width: 1186px;
    margin: auto;

  }
  .content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3.8rem;
    ul {
> div{
  margin-bottom: 2.1rem;
  &:last-child{
    margin-bottom: 0;
  }
  li.small{
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
}
      li {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 2.1rem;
        color: #FFFFFF;
margin-bottom: 1.3rem;
        img{
          margin-right: 1.5rem;
        }
      }
      li a{
        color: white;
      }

      li.head {
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 2.7rem;
        margin-bottom: 3.2rem;
        display: block;
        &:after{
          content: '';
          display: block;
          width: 52px;
          height: .2rem;
background-color: #8FC54D;
          margin-top: .8rem;
        }
      }
    }

  }
  .tel_li a{
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
  }

  .copyRight {
    p {
      color: #ffffff;
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 1.5rem;
      text-align: left;
      display: flex;
    }
    a{
      color: #ffffff;
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 1.5rem;
      margin-left: 5px;
    }
  }

}

@media (max-width: 1200px) {
  footer .content ul li img {
    max-width: 2rem;
  }
}
@media (max-width: 480px) {
  footer{
    padding: 5rem 3rem;
  }
  footer .content{
    flex-direction: column;
    row-gap: 5rem;
  }

}
