.earch_result_cont{
    width: 100%;
    height: 50vh;
}
.search_result_cont_header{
    width: 100%;
    height: 87px;
    background: #332486;
    padding: 20px;
}
.search_text{
    text-align: center;
    color: #FFF;
    font-size: 32px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.search_result_block{
    margin-top: 28px;
    min-height: 500px;
}
.no_result{
    text-align: center;
    color: #000;
    font-size: 24px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;

}