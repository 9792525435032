.signBanner {
  background-color: #F5F5F5;
  padding: 7.6rem 3rem 25.4rem;

  .logo {
    margin: 0 auto 7.5rem;
  }

  .div {
    max-width: 51.4rem;
    background: #FFFFFF;
    border: 1px solid #E8E7E7;
    border-radius: .8rem;
    padding: 6.4rem 6rem;
    margin: auto;
  }

  h1 {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 2.0rem;
    line-height: 2.3rem;

    color: #3E4343;
    margin-bottom: 3.4rem;
  }

  label {
    display: block;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.4rem;
    margin-bottom: 3.4rem;
    color: #565656;

    &[for=rememberMe] {
      display: flex;
      align-items: center;
      margin-bottom: 0;
    }

  }

  input {
    padding: 9px 15px 10px;

    width: 100%;
    margin-top: .2rem;
    background: #FFFFFF;
    border: 1px solid rgba(51, 36, 134, 0.7);
    border-radius: 4px;

    &[type=radio] {
      width: max-content;
      background-color: red;
      margin: 0 .6rem 0 0;

    }
  }

  .btn {
    display: block;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.9rem;

    color: #FFFFFF;

    background: #332486;
    border-radius: 29px;
    padding: 1.2rem 3.2rem;
    margin: 4.7rem auto 3rem;
  }

  form > div {
    display: flex;
    align-items: center;

    button {
      color: #332486;
      width: 100%;
      max-width: max-content;
    }
  }
}

.forget_pass {
  white-space: nowrap;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #332486;
}

.enter_email_text {
  margin-top: -3.2rem !important;
  margin-bottom: 48px;
}


.tab_active {
  padding: 0px 32px 12px;
  border-bottom: 1px solid #332486;
}

.tab {
  padding: 0px 32px 12px;
}

.sign_up_radiobtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sign_up_radiobtn label {
  display: flex;
  align-items: center;
  width: max-content !important;
}


.tabs_block {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabs_block button {
  margin-bottom: 24px;
}

.showTab {
  display: block;
}

.hideTab {
  display: none;
}

.beforeSigncont{
  background-color: #F5F5F5;
  padding: 7.6rem 3rem 25.4rem;

  .logo {
    margin: 0 auto 7.5rem;
  }

  .div {
    max-width: 51.4rem;
    background: #FFFFFF;
    border: 1px solid #E8E7E7;
    border-radius: .8rem;
    padding: 4.4rem 3rem;
    margin: auto;
    text-align: center;
  }

  h1 {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 2.0rem;
    line-height: 2.3rem;
    color: #3E4343;
  }

  h2{
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 2.0rem;
    line-height: 2.3rem;
    color: #3E4343;
    margin-bottom: 3.4rem;
  }
  .btn {
    display: block;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: #FFFFFF;
    background: #332486;
    border-radius: 29px;
    padding: 1.2rem 3.2rem;
    margin: auto;
  }
  div{
    margin-bottom: 24px;
    text-align: center;
  }
}